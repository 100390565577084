export const CATEGORY_APPROVAL_MODIFICATION_TYPES = Object.freeze({
  CREATE: 1,
  UPDATE: 2,
  MERGE: 3,
  MOVE: 4,
  DELETE: 5,
});

export const CATEGORY_APPROVAL_STATUSES = Object.freeze({
  ALL: null,
  NEW: 1,
  APPROVED: 2,
  DELETED: 3,
});

export const CATEGORY_APPROVAL_MODIFICATION_TYPE_OPTIONS = [
  { text: 'Tạo', value: CATEGORY_APPROVAL_MODIFICATION_TYPES.CREATE },
  { text: 'Cập nhật', value: CATEGORY_APPROVAL_MODIFICATION_TYPES.UPDATE },
  { text: 'Gộp', value: CATEGORY_APPROVAL_MODIFICATION_TYPES.MERGE },
  { text: 'Di chuyển', value: CATEGORY_APPROVAL_MODIFICATION_TYPES.MOVE },
  { text: 'Xóa', value: CATEGORY_APPROVAL_MODIFICATION_TYPES.DELETE },
];

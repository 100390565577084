export const CATEGORY_APPROVAL_FIELDS = [
  {
    key: 'stt',
    label: 'STT',
    sortable: false,
    thStyle: { textAlign: 'center', fontWeight: 600, color: '#181c32' },
  },
  {
    key: 'createdAt',
    label: 'Thời gian tạo',
    sortable: false,
    thStyle: { textAlign: 'center', fontWeight: 600, color: '#181c32' },
  },
  {
    key: 'modificationType',
    label: 'Thao tác',
    sortable: false,
    tdClass: 'nameCateClass',
    thClass: 'nameCateClass',
    thStyle: { fontWeight: 600, color: '#181c32' },
  },
  {
    key: 'createdByName',
    label: 'Tên người yêu cầu',
    sortable: false,
    tdClass: 'nameCateClass',
    thClass: 'nameCateClass',
    thStyle: { fontWeight: 600, color: '#181c32' },
  },
  {
    key: 'currentInfo',
    label: 'Thông tin hiện tại',
    sortable: false,
    tdClass: 'nameCateClass',
    thClass: 'nameCateClass',
    thStyle: { fontWeight: 600, color: '#181c32' },
  },
  {
    key: 'needsApprovedChangeInfo',
    label: 'Thông tin cần thay đổi',
    sortable: false,
    tdClass: 'nameCateClass',
    thClass: 'nameCateClass',
    thStyle: { fontWeight: 600, color: '#181c32' },
  },
  {
    key: 'confirm',
    label: 'Xác nhận',
    sortable: false,
    tdClass: 'nameCateClass',
    thClass: 'nameCateClass',
    thStyle: { fontWeight: 600, color: '#181c32' },
  },
  {
    key: 'requestReason',
    label: 'Lý do yêu cầu',
    sortable: false,
    tdClass: 'nameCateClass',
    thClass: 'nameCateClass',
    thStyle: { fontWeight: 600, color: '#181c32' },
  },
  {
    key: 'status',
    label: 'Trạng thái',
    sortable: false,
    tdClass: 'nameCateClass',
    thClass: 'nameCateClass',
    thStyle: { fontWeight: 600, color: '#181c32' },
  },
  {
    key: 'actions',
    label: 'Hành động',
  },
];

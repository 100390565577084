<template>
  <div>
    <KTCodePreview v-bind:title="'Danh sách danh mục sản phẩm'">
      <template v-slot:toolbar>
        <b-row>
          <b-button
            size="sm"
            variant="primary"
            class="font-weight-bolder"
            v-show="checkPermission('CATE_INSERT')"
            @click="showModalAddCate"
            v-if="!isEnabledCategoryApproval"
          >
            <i class="flaticon2-add-1 icon-size-1-rem"></i>
            Tạo mới
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            class="font-weight-bolder mr-4"
            v-show="checkPermission('CATE_APPROVAL_VIEW')"
            @click="showModalCategoryApprovals"
            v-if="isEnabledCategoryApproval"
          >
            <i class="flaticon2-add-1 icon-size-1-rem"></i>
            Danh sách các yêu cầu
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            class="font-weight-bolder"
            v-show="checkPermission('CATE_APPROVAL_INSERT_CREATE')"
            @click="showModalAddCateApproval"
            v-if="isEnabledCategoryApproval"
          >
            <i class="flaticon2-add-1 icon-size-1-rem"></i>
            Tạo phiếu yêu cầu thêm danh mục mới
          </b-button>
        </b-row>
      </template>
      <template v-slot:preview>
        <div class="mb-5">
          <b-form>
            <div class="row">
              <b-col cols="8">
                <div class="row">
                  <b-col md="4">
                    <b-input
                      size="sm"
                      placeholder="Tìm kiếm theo tên"
                      v-model.trim="searchName"
                      v-on:keyup.enter="searchCate"
                      autocomplete="off"
                    />
                  </b-col>
                  <b-col md="4">
                    <b-input
                      size="sm"
                      placeholder="Tìm kiếm theo mã"
                      v-model.trim="searchCode"
                      v-on:keyup.enter="searchCate"
                      autocomplete="off"
                    />
                  </b-col>
                  <b-col md="1">
                    <b-button
                      size="sm"
                      variant="primary"
                      style="width: 70px"
                      @click="onFilter"
                      >Lọc
                    </b-button>
                  </b-col>
                  <b-col class="ml-6 d-flex">
                    <b-button
                      v-if="show"
                      v-b-tooltip.hover
                      title="Ẩn tất cả danh mục"
                      size="sm"
                      variant="secondary"
                      @click="hideAllCollapse"
                    >
                      <i style="padding-right: 0" class="fa fa-eye-slash"></i>
                    </b-button>
                    <b-button
                      v-else
                      v-b-tooltip.hover
                      title="Hiện tất cả danh mục"
                      size="sm"
                      variant="secondary"
                      @click="showAllCollapse"
                    >
                      <i style="padding-right: 0" class="fa fa-eye"></i>
                    </b-button>
                    <b-button
                      size="sm"
                      class="ml-2"
                      v-b-tooltip.hover
                      title="Reset dữ liệu"
                      @click="resetData"
                      variant="secondary"
                    >
                      <i style="padding-right: 0" class="fa fa-redo"></i>
                    </b-button>
                  </b-col>
                </div>
              </b-col>
            </div>
          </b-form>
        </div>

        <b-modal
          ref="add-cate-modal"
          @hide="onHide"
          hide-footer
          title="Thêm mới danh mục"
        >
          <v-form v-model="valid" lazy-validation>
            <div class="w-50">
              <b-form-group>
                <label>Danh mục cha:</label>
                <b-form-input
                  disabled
                  v-if="parentCateId"
                  v-model="parentCateName"
                />
                <treeselect
                  v-else
                  :options="listItem"
                  :multiple="false"
                  placeholder="Chọn danh mục cha"
                  :match-keys="['id', 'label']"
                  v-model="selectedParent"
                  :clearable="true"
                  :show-count="true"
                  openDirection="bottom"
                >
                </treeselect>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Tên:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  placeholder="Nhập tên"
                  v-model.trim="name"
                  :state="validateState('name')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.name.required"
                  >Yêu cầu nhập tên danh mục
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Mã:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  placeholder="Nhập mã"
                  v-model.trim="code"
                  :state="validateState('code')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.code.required"
                  >Yêu cầu nhập mã danh mục
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <b-button
              size="sm"
              variant="primary"
              class="mr-3"
              style="width: 70px"
              v-show="checkPermission('CATE_INSERT')"
              @click="createCategory"
              >Lưu
            </b-button>
            <b-button
              size="sm"
              variant="secondary"
              style="width: 70px"
              @click="hideModalAddCate"
              >Hủy
            </b-button>
          </v-form>
        </b-modal>
        <b-modal ref="update-cate-modal" hide-footer title="Cập nhật danh mục">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="w-50">
              <b-form-group>
                <label>Danh mục cha:</label>
                <treeselect
                  :options="listItem"
                  :multiple="false"
                  placeholder="Chọn danh mục cha"
                  :match-keys="['id', 'label']"
                  v-model="selectedParent"
                  :clearable="true"
                  :show-count="true"
                  openDirection="bottom"
                >
                </treeselect>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Tên:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  placeholder="Nhập tên"
                  v-model="name"
                  :state="validateState('name')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.name.required"
                  >Yêu cầu nhập tên danh mục
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Mã:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  placeholder="Nhập mã"
                  v-model="code"
                  :state="validateState('code')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.code.required"
                  >Yêu cầu nhập mã danh mục
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="w-50 mb-5">
              <label>Thuộc ngành hàng:</label>
              <Autosuggest
                class="border-radius-none"
                :model="searchPurchasingDepartment"
                :suggestions="listPurchasingDepartment"
                placeholder="Tìm kiếm theo tên ngành hàng"
                :limit="100"
                @select="onSelectedPurchasingDepartment"
                @change="onInputChangePurchasingDepartment"
                suggestionName="name"
              />
            </div>

            <b-button
              size="sm"
              variant="primary"
              class="mr-3 mt-2"
              style="width: 70px"
              @click="updateCategory"
              v-show="checkPermission('CATE_UPDATE')"
              >Lưu
            </b-button>
            <b-button
              style="width: 70px"
              variant="secondary"
              size="sm"
              @click="hideModalUpdateCate"
              >Hủy
            </b-button>
          </v-form>
        </b-modal>
        <b-modal
          ref="merge-tree-cate-modal"
          hide-footer
          title="Di chuyển cây danh mục"
        >
          <v-form>
            <div class="w-50">
              <b-form-group>
                <label>Danh mục cha:</label>
                <treeselect
                  :options="listItem"
                  :multiple="false"
                  placeholder="Chọn danh mục cha"
                  :match-keys="['id', 'label']"
                  v-model="targetCateToBeMerged"
                  :clearable="true"
                  :show-count="true"
                  openDirection="bottom"
                >
                </treeselect>
              </b-form-group>

              <b-button
                size="sm"
                variant="primary"
                class="mr-3"
                style="width: 130px"
                @click="mergeTreeCategory"
                >Di chuyển tới đây
              </b-button>
              <b-button
                style="width: 70px"
                variant="secondary"
                size="sm"
                @click="hideMergeTreeModal"
                >Hủy
              </b-button>
            </div>
          </v-form>
        </b-modal>
        <b-modal ref="merge-cate-modal" hide-footer title="Gộp danh mục">
          <div class="d-flex">
            <div class="w-50">
              <b-form-group>
                <label>Từ danh mục:</label>
                <p>
                  <b> {{ categoryMerge.picked.name }}</b>
                </p>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <b-form-group>
                  <label>Đến danh mục:</label>
                  <b-form-select
                    size="sm"
                    v-model="categoryMerge.mergedId"
                    :options="listMergeTreeCate"
                    @change="onChangeMergeCate"
                    value-field="id"
                    text-field="name"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled
                        >Chọn danh mục
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-form-group>
            </div>
          </div>

          <b-button
            size="sm"
            variant="primary"
            class="mr-3"
            style="width: 70px"
            @click="onMergeCate"
            v-show="checkPermission('CATE_UPDATE')"
            >Lưu
          </b-button>
          <b-button
            style="width: 70px"
            variant="secondary"
            size="sm"
            @click="$refs['merge-cate-modal'].hide()"
            >Hủy
          </b-button>
        </b-modal>

        <b-modal
          ref="add-cate-approval-modal"
          @hide="onHide"
          hide-footer
          title="Thêm mới yêu cầu tạo danh mục"
        >
          <b-alert show variant="warning"
            >Yêu cầu sẽ cần được duyệt bởi
            <strong>
              <em>Admin Ngành hàng</em>
            </strong></b-alert
          >
          <v-form v-model="valid" lazy-validation>
            <div class="w-50">
              <b-form-group>
                <label>Danh mục cha:</label>
                <b-form-input
                  disabled
                  v-if="parentCateId"
                  v-model="parentCateName"
                />
                <treeselect
                  v-else
                  :options="listItem"
                  :multiple="false"
                  placeholder="Chọn danh mục cha"
                  :match-keys="['id', 'label']"
                  v-model="selectedParent"
                  :clearable="true"
                  :show-count="true"
                  openDirection="bottom"
                >
                </treeselect>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Tên:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  placeholder="Nhập tên"
                  v-model.trim="name"
                  :state="validateState('name')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.name.required"
                  >Yêu cầu nhập tên danh mục
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Mã:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  placeholder="Nhập mã"
                  v-model.trim="code"
                  :state="validateState('code')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.code.required"
                  >Yêu cầu nhập mã danh mục
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="w-50 mb-5">
              <label>Thuộc ngành hàng:</label>
              <Autosuggest
                class="border-radius-none"
                :model="searchPurchasingDepartment"
                :suggestions="listPurchasingDepartment"
                placeholder="Tìm kiếm theo tên ngành hàng"
                :limit="100"
                @select="onSelectedPurchasingDepartment"
                @change="onInputChangePurchasingDepartment"
                suggestionName="name"
              />
            </div>
            <div class="w-50 mb-5">
              <label>Lý do yêu cầu:</label>
              <b-form-textarea
                size="sm"
                placeholder="Nhập lý do yêu cầu"
                v-model.trim="requestReason"
                :state="validateState('requestReason')"
              ></b-form-textarea>
              <b-form-invalid-feedback v-if="!$v.requestReason.required"
                >Yêu cầu nhập lý do
              </b-form-invalid-feedback>
            </div>

            <b-button
              size="sm"
              variant="primary"
              class="mr-3"
              style="width: 70px"
              v-show="checkPermission('CATE_APPROVAL_INSERT_CREATE')"
              @click="createCategoryApproval"
              >Lưu
            </b-button>
            <b-button
              size="sm"
              variant="secondary"
              style="width: 70px"
              @click="hideModalAddCateApproval"
              >Hủy
            </b-button>
          </v-form>
        </b-modal>
        <b-modal
          ref="cate-approvals-modal"
          @hide="onHide"
          hide-footer
          title="Danh sách các yêu cầu"
          size="xl"
        >
          <b-form-group>
            <label>Lọc theo danh mục đối tượng:</label>
            <treeselect
              :options="listItem"
              :multiple="false"
              placeholder="Chọn danh mục"
              :match-keys="['id', 'label']"
              v-model="selectedCategoryId"
              :clearable="true"
              :show-count="true"
              openDirection="bottom"
            >
            </treeselect>
          </b-form-group>

          <b-form-group label="Theo thao tác danh mục:">
            <b-form-checkbox-group
              v-model="selectedSearchMethods"
              :options="searchMethodOptions"
              name="buttons-1"
              buttons
            ></b-form-checkbox-group>
          </b-form-group>
          <b-form-group>
            <label style="font-weight: 500px">Theo nhân sự:</label>
            <multiselect
              v-model="selectedEmployees"
              placeholder="Tìm kiếm theo tên nhân sự"
              label="employeeName"
              track-by="employeeName"
              :options="employeeList"
              :multiple="true"
              :taggable="true"
              :selectLabel="'Chọn nhân sự'"
              :deselectLabel="'Xoá nhân sự'"
            ></multiselect>
          </b-form-group>

          <b-button
            size="sm"
            variant="primary"
            class="font-weight-bolder"
            @click="fetchCategoryApprovals"
            block
          >
            Lọc
          </b-button>

          <b-nav class="my-3" tabs pills>
            <b-nav-item
              v-for="item in listStatus"
              :key="item.id"
              @click="handleFilterByStatus(item)"
              :active="item.id === selectedStatus"
            >
              {{ item.name }}
            </b-nav-item>
          </b-nav>
          <b-pagination
            pills
            v-show="numberOfPage >= 2"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="limit"
            @change="handlePageChange"
            align="right"
            class="mt-3"
            style="border-radius: 5px"
          ></b-pagination>
          <b-table
            striped
            hover
            class="category-approval-list"
            :fields="CATEGORY_APPROVAL_FIELDS()"
            :items="categoryApprovals"
            sticky-header="800px"
            show-empty
            empty-text="Không có dữ liệu"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Đang tải...</strong>
              </div>
            </template>
            <!-- Format id column -->
            <template v-slot:cell(createdAt)="row">
              {{
                formatDate(row.item.createdAt, CLIENT_DATE_TIME_NO_SECONDS())
              }}
            </template>
            <template v-slot:cell(modificationType)="row">
              <b-badge
                :variant="
                  getModificationTypeBadgeVariant(row.item.modificationType)
                "
                >{{ getModificationTypeName(row.item.modificationType) }}
              </b-badge>
            </template>
            <template v-slot:cell(currentInfo)="row">
              <div>
                Tên danh mục: {{ row.item.currentInfo.name }}
                <hr />
                Mã danh mục: {{ row.item.currentInfo.code }}
                <hr />
                Danh mục cha: {{ row.item.currentInfo.parentCategoryName }}
                <hr />
                Ngành hàng: {{ row.item.currentInfo.purchasingDepartmentName }}
              </div>
            </template>
            <template v-slot:cell(needsApprovedChangeInfo)="row">
              <div
                v-if="
                  [
                    CATEGORY_APPROVAL_MODIFICATION_TYPES.CREATE,
                    CATEGORY_APPROVAL_MODIFICATION_TYPES.UPDATE,
                  ].includes(row.item.modificationType)
                "
              >
                Tên danh mục: {{ row.item.needsApprovedChangeInfo.name }}
                <hr />
                Mã danh mục: {{ row.item.needsApprovedChangeInfo.code }}
                <hr />
                Danh mục cha:
                {{ row.item.needsApprovedChangeInfo.parentCategoryName }}
                <hr />
                Ngành hàng:
                {{ row.item.needsApprovedChangeInfo.purchasingDepartmentName }}
              </div>
              <div
                v-if="
                  row.item.modificationType ===
                  CATEGORY_APPROVAL_MODIFICATION_TYPES.MERGE
                "
              >
                Gộp danh mục
                <strong>
                  <em>
                    {{ row.item.currentInfo.name }}
                  </em>
                </strong>
                &#10; vào danh mục
                <strong>
                  <em>
                    {{ row.item.needsApprovedChangeInfo.name }}
                  </em>
                </strong>
              </div>
              <div
                v-if="
                  row.item.modificationType ===
                  CATEGORY_APPROVAL_MODIFICATION_TYPES.MOVE
                "
              >
                Di chuyển danh mục
                <strong>
                  <em>
                    {{ row.item.currentInfo.name }}
                  </em>
                </strong>
                &#10; vào danh mục
                <strong>
                  <em>
                    {{ row.item.needsApprovedChangeInfo.name }}
                  </em>
                </strong>
              </div>
              <div
                v-if="
                  row.item.modificationType ===
                  CATEGORY_APPROVAL_MODIFICATION_TYPES.DELETE
                "
              >
                Xóa danh mục
                <strong>
                  <em>
                    {{ row.item.needsApprovedChangeInfo.name }}
                  </em>
                </strong>
              </div>
            </template>
            <template v-slot:cell(status)="row">
              <b-badge
                :variant="
                  getCategoryApprovalStatusBadgeVariant(row.item.status)
                "
                >{{ getCategoryApprovalStatusName(row.item.status) }}
              </b-badge>
            </template>
            <template v-slot:cell(confirm)="row">
              <div
                v-if="
                  checkPermission('CATE_APPROVAL_APPROVE') &&
                  row.item.status === CATEGORY_APPROVAL_STATUSES.NEW
                "
                @click="showConfirmBox(row.item)"
                style="cursor: pointer"
                class="d-flex justify-content-center align-items-center"
              >
                <v-icon
                  small
                  style="font-size: 13px"
                  v-b-tooltip
                  title="Xác nhận phiếu"
                  >menu-icon flaticon2-checkmark
                </v-icon>
              </div>

              <div
                v-else-if="
                  row.item.status === CATEGORY_APPROVAL_STATUSES.APPROVED
                "
              >
                <p class="text-center text-primary mb-0">Đã xác nhận</p>
                <p class="text-center">{{ row.item.approvedByName }}</p>
                <p class="text-center text-primary mb-0">
                  {{
                    formatDate(
                      row.item.approvedAt,
                      CLIENT_DATE_TIME_NO_SECONDS(),
                    )
                  }}
                </p>
              </div>
            </template>
            <template v-slot:cell(actions)="row">
              <div class="d-flex justify-content-center">
                <b-dropdown
                  v-if="
                    checkPermissions(['CATE_APPROVAL_INSERT_DELETE']) &&
                    row.item.status === CATEGORY_APPROVAL_STATUSES.NEW
                  "
                  size="sm"
                  id="dropdown-left"
                  no-caret
                  right
                >
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem; padding-right: 0px"
                      class="flaticon2-settings"
                    ></i>
                  </template>
                  <b-dropdown-item
                    @click="showConfirmDeleteBox(row.item)"
                    v-if="
                      checkPermission('CATE_APPROVAL_INSERT_DELETE') &&
                      row.item.status === CATEGORY_APPROVAL_STATUSES.NEW
                    "
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem; color: #d33"
                        class="flaticon2-rubbish-bin-delete-button"
                      ></i>
                      &nbsp; Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </b-modal>
        <b-modal
          ref="add-edit-cate-approval-modal"
          @hide="onHide"
          hide-footer
          title="Thêm mới yêu cầu sửa danh mục"
        >
          <b-alert show variant="warning"
            >Yêu cầu sẽ cần được duyệt bởi
            <strong>
              <em>Admin Ngành hàng</em>
            </strong></b-alert
          >
          <v-form v-model="valid" lazy-validation>
            <div class="w-50">
              <b-form-group>
                <label>Danh mục cha:</label>
                <b-form-input
                  disabled
                  v-if="parentCateId"
                  v-model="parentCateName"
                />
                <treeselect
                  :disabled="
                    Boolean(
                      !checkPermission('CATE_APPROVAL_INSERT_UPDATE') &&
                        checkViewOnlyCategoryApproval,
                    )
                  "
                  v-else
                  :options="listItem"
                  :multiple="false"
                  placeholder="Chọn danh mục cha"
                  :match-keys="['id', 'label']"
                  v-model="selectedParent"
                  :clearable="true"
                  :show-count="true"
                  openDirection="bottom"
                >
                </treeselect>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Tên:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  placeholder="Nhập tên"
                  :disabled="
                    Boolean(
                      !checkPermission('CATE_APPROVAL_INSERT_UPDATE') &&
                        checkViewOnlyCategoryApproval,
                    )
                  "
                  v-model.trim="name"
                  :state="validateState('name')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.name.required"
                  >Yêu cầu nhập tên danh mục
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <label>Mã:</label>
                <span class="text-danger">*</span>
                <b-form-input
                  size="sm"
                  :disabled="
                    Boolean(
                      !checkPermission('CATE_APPROVAL_INSERT_UPDATE') &&
                        checkViewOnlyCategoryApproval,
                    )
                  "
                  placeholder="Nhập mã"
                  v-model.trim="code"
                  :state="validateState('code')"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!$v.code.required"
                  >Yêu cầu nhập mã danh mục
                </b-form-invalid-feedback>
              </b-form-group>
            </div>

            <div class="w-50 mb-5">
              <label>Thuộc ngành hàng:</label>
              <Autosuggest
                class="border-radius-none"
                :model="searchPurchasingDepartment"
                :suggestions="listPurchasingDepartment"
                placeholder="Tìm kiếm theo tên ngành hàng"
                :limit="100"
                @select="onSelectedPurchasingDepartment"
                @change="onInputChangePurchasingDepartment"
                suggestionName="name"
                :disabled="
                  Boolean(
                    !checkPermission('CATE_APPROVAL_INSERT_UPDATE') &&
                      checkViewOnlyCategoryApproval,
                  )
                "
              />
            </div>
            <div class="w-50 mb-5">
              <label>Lý do yêu cầu:</label>
              <b-form-textarea
                size="sm"
                placeholder="Nhập lý do yêu cầu"
                v-model.trim="requestReason"
                :state="validateState('requestReason')"
                :disabled="
                  Boolean(
                    !checkPermission('CATE_APPROVAL_INSERT_UPDATE') &&
                      checkViewOnlyCategoryApproval,
                  )
                "
              ></b-form-textarea>
              <b-form-invalid-feedback v-if="!$v.requestReason.required"
                >Yêu cầu nhập lý do
              </b-form-invalid-feedback>
            </div>

            <b-button
              size="sm"
              variant="primary"
              class="mr-3"
              style="width: 70px"
              v-show="checkPermission('CATE_APPROVAL_INSERT_UPDATE')"
              @click="createEditCategoryApproval"
              >Lưu
            </b-button>
            <b-button
              size="sm"
              variant="secondary"
              style="width: 70px"
              @click="hideModalAddEditCateApproval"
              >Hủy
            </b-button>
          </v-form>
        </b-modal>
        <b-modal
          ref="add-move-cate-approval-modal"
          hide-footer
          title="Tạo yêu cầu di chuyển danh mục"
        >
          <b-alert show variant="warning"
            >Yêu cầu sẽ cần được duyệt bởi
            <strong>
              <em>Admin Ngành hàng</em>
            </strong></b-alert
          >
          <v-form>
            <div class="w-50">
              <b-form-group>
                <label>Danh mục cha:</label>
                <treeselect
                  :options="listItem"
                  :multiple="false"
                  placeholder="Chọn danh mục cha"
                  :match-keys="['id', 'label']"
                  v-model="targetCateToBeMerged"
                  :clearable="true"
                  :show-count="true"
                  openDirection="bottom"
                >
                </treeselect>
              </b-form-group>

              <b-form-group>
                <label>Lý do yêu cầu:</label>
                <b-form-textarea
                  size="sm"
                  placeholder="Nhập lý do yêu cầu"
                  v-model.trim="requestReason"
                  :state="validateState('requestReason')"
                ></b-form-textarea>
                <b-form-invalid-feedback v-if="!$v.requestReason.required"
                  >Yêu cầu nhập lý do
                </b-form-invalid-feedback>
              </b-form-group>

              <b-button
                size="sm"
                variant="primary"
                class="mr-3"
                style="width: 130px"
                @click="createMoveCategoryApproval"
                >Di chuyển tới đây
              </b-button>
              <b-button
                style="width: 70px"
                variant="secondary"
                size="sm"
                @click="hideMoveCategoryApprovalModal"
                >Hủy
              </b-button>
            </div>
          </v-form>
        </b-modal>
        <b-modal
          ref="add-merge-cate-approval-modal"
          hide-footer
          title="Tạo yêu cầu gộp danh mục"
        >
          <div class="d-flex">
            <div class="w-50">
              <b-form-group>
                <label>Từ danh mục:</label>
                <p>
                  <b> {{ categoryMerge.picked.name }}</b>
                </p>
              </b-form-group>
            </div>
            <div class="w-50">
              <b-form-group>
                <b-form-group>
                  <label>Đến danh mục:</label>
                  <b-form-select
                    size="sm"
                    v-model="categoryMerge.mergedId"
                    :options="listMergeTreeCate"
                    @change="onChangeMergeCate"
                    value-field="id"
                    text-field="name"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null" disabled
                        >Chọn danh mục
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-form-group>
            </div>
          </div>

          <div class="w-50 mb-5">
            <label>Lý do yêu cầu:</label>
            <b-form-textarea
              size="sm"
              placeholder="Nhập lý do yêu cầu"
              v-model.trim="requestReason"
              :state="validateState('requestReason')"
            ></b-form-textarea>
            <b-form-invalid-feedback v-if="!$v.requestReason.required"
              >Yêu cầu nhập lý do
            </b-form-invalid-feedback>
          </div>

          <b-button
            size="sm"
            variant="primary"
            class="mr-3"
            style="width: 70px"
            @click="createMergeCategoryApproval"
            v-show="checkPermission('CATE_UPDATE')"
            >Lưu
          </b-button>
          <b-button
            style="width: 70px"
            variant="secondary"
            size="sm"
            @click="hideModalMergeCateApproval"
            >Hủy
          </b-button>
        </b-modal>
        <b-modal
          ref="add-delete-cate-approval-modal"
          hide-footer
          title="Tạo yêu cầu xóa danh mục"
        >
          <div class="w-50 mb-5">
            <label>Lý do yêu cầu:</label>
            <b-form-textarea
              size="sm"
              placeholder="Nhập lý do yêu cầu"
              v-model.trim="requestReason"
              :state="validateState('requestReason')"
            ></b-form-textarea>
            <b-form-invalid-feedback v-if="!$v.requestReason.required"
              >Yêu cầu nhập lý do
            </b-form-invalid-feedback>
          </div>

          <b-button
            size="sm"
            variant="primary"
            class="mr-3"
            style="width: 70px"
            @click="addDeleteCategoryApproval"
            v-show="checkPermission('CATE_APPROVAL_INSERT_DELETE')"
            >Lưu
          </b-button>
          <b-button
            style="width: 70px"
            variant="secondary"
            size="sm"
            @click="hideModalDeleteCateApproval"
            >Hủy
          </b-button>
        </b-modal>

        <div ref="loadMoreCategory">
          <b-table
            :items="listItem"
            :fields="fields"
            bordered
            hover
            :busy="onLoading"
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="6"></vcl-table>
            </template>
            <template v-slot:cell(stt)="row">
              <div class="d-flex justify-content-center w-6">
                <span v-text="row.item.stt"></span>
              </div>
            </template>
            <template v-slot:cell(name)="row">
              <!-- list item level 0 -->
              <li
                class="list-level-0"
                v-b-toggle:[`list-itemlv1-${row.item.id}`]
              >
                <p v-html="highlightMatchesName(row.item.name)"></p>
                <span
                  class="categories_quantity"
                  v-if="row.item.children.length"
                >
                  ({{ row.item.children.length }})
                </span>
                <!-- Make the arrow appear if parent item has child items -->
                <i
                  v-if="row.item.children.length > 0"
                  class="fa fa-angle-down arrow-level-0"
                ></i>
              </li>
              <b-collapse
                :ref="`collapse-${row.item.id}`"
                :id="`list-itemlv1-${row.item.id}`"
              >
                <ListItemCate
                  v-show="!isEnabledCategoryApproval"
                  v-for="itemLv1 in row.item.children"
                  :key="itemLv1.id"
                  :item="itemLv1"
                  @editItem="editItem"
                  @popupMergeCate="popupMergeCate"
                  @showMergeTreeModal="showMergeTreeModal"
                  @addChildCate="addChildCate"
                  @showDeleteAlert="showDeleteAlert"
                  :internalCate="true"
                  :searchName="searchName"
                  ref="collapseAll"
                >
                </ListItemCate>
                <ListItemCateV2
                  v-show="isEnabledCategoryApproval"
                  v-for="itemLv1 in row.item.children"
                  :key="`lic-${itemLv1.id}`"
                  :item="itemLv1"
                  @editItem="addEditCategoryApproval"
                  @popupMergeCate="addMergeCategoryApproval"
                  @showMergeTreeModal="addMoveCategoryApproval"
                  @addChildCate="addChildCateForCategoryApproval"
                  @showDeleteAlert="showCreateDeleteCategoryApprovalAlert"
                  :internalCate="true"
                  :searchName="searchName"
                  ref="collapseAll"
                >
                </ListItemCateV2>
              </b-collapse>
            </template>
            <template v-slot:cell(code)="row">
              <b v-html="highlightMatchesCode(row.item.code)"></b>
            </template>
            <template v-slot:cell(actions)="row">
              <div class="d-flex justify-content-center">
                <b-dropdown size="sm" no-caret right lazy>
                  <template slot="button-content">
                    <i class="flaticon2-settings icon-size-1-rem pr-0"></i>
                  </template>
                  <b-dropdown-item
                    @click="editItem(row.item)"
                    v-if="!isEnabledCategoryApproval && checkViewOnly()"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i class="flaticon2-box-1 icon-size-1-rem"></i>
                      &nbsp; Chi tiết
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="addEditCategoryApproval(row.item)"
                    v-if="
                      isEnabledCategoryApproval &&
                      checkViewOnlyCategoryApproval()
                    "
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i class="flaticon2-box-1 icon-size-1-rem"></i>
                      &nbsp; Chi tiết
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="editItem(row.item)"
                    v-if="!isEnabledCategoryApproval && !checkViewOnly()"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i class="flaticon2-pen icon-size-1-rem"></i>
                      &nbsp; Chỉnh sửa
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="addEditCategoryApproval(row.item)"
                    v-show="
                      isEnabledCategoryApproval &&
                      !checkViewOnlyCategoryApproval()
                    "
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i class="flaticon2-pen icon-size-1-rem"></i>
                      &nbsp; Thêm phiếu chỉnh sửa
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="popupMergeCate(row.item)"
                    v-if="
                      !isEnabledCategoryApproval &&
                      checkPermission('CATE_MERGE')
                    "
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        class="fas fa-layer-group text-warning icon-size-1-rem"
                      ></i>
                      &nbsp; Gộp danh mục
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="addMergeCategoryApproval(row.item)"
                    v-if="
                      isEnabledCategoryApproval &&
                      checkPermission('CATE_APPROVAL_INSERT_MERGE')
                    "
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        class="fas fa-layer-group text-warning icon-size-1-rem"
                      ></i>
                      &nbsp; Thêm phiếu gộp danh mục
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="showMergeTreeModal(row.item)"
                    v-show="
                      !isEnabledCategoryApproval && checkPermission('CATE_MOVE')
                    "
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="color: #8908fd"
                        class="flaticon-arrows icon-size-1-rem"
                      ></i>
                      &nbsp; Di chuyển danh mục
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="addMoveCategoryApproval(row.item)"
                    v-show="
                      isEnabledCategoryApproval &&
                      checkPermission('CATE_APPROVAL_INSERT_MOVE')
                    "
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="color: #8908fd"
                        class="flaticon-arrows icon-size-1-rem"
                      ></i>
                      &nbsp; Thêm phiếu di chuyển
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="addChildCate(row.item)"
                    v-show="
                      !isEnabledCategoryApproval &&
                      checkPermission('CATE_INSERT')
                    "
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="color: #1bc5bc"
                        class="fas fa-plus icon-size-1-rem"
                      ></i>
                      &nbsp; Thêm danh mục con
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="addChildCateForCategoryApproval(row.item)"
                    v-show="
                      isEnabledCategoryApproval &&
                      checkPermission('CATE_APPROVAL_INSERT_CREATE')
                    "
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="color: #1bc5bc"
                        class="fas fa-plus icon-size-1-rem"
                      ></i>
                      &nbsp; Thêm phiếu tạo danh mục con
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="showDeleteAlert(row.item)"
                    v-show="
                      !isEnabledCategoryApproval &&
                      checkPermission('CATE_DELETE')
                    "
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="color: #d33"
                        class="flaticon2-rubbish-bin-delete-button icon-size-1-rem"
                      ></i>
                      &nbsp; Xóa
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="showCreateDeleteCategoryApprovalAlert(row.item)"
                    v-show="
                      isEnabledCategoryApproval &&
                      checkPermission('CATE_APPROVAL_INSERT_DELETE')
                    "
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem; color: #d33"
                        class="flaticon2-rubbish-bin-delete-button"
                      ></i>
                      &nbsp; Thêm phiếu xóa danh mục
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>

          <b-row>
            <b-col>
              <p class="mt-3 text-dark" style="font-weight: 500">
                Tổng số:
                {{ listItem.length }}
              </p>
            </b-col>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
.icon-size-1-rem {
  font-size: 1rem !important;
}

.icon {
  padding-top: 7px;
}

.ml-10 {
  margin-left: 5px;
}

.ml-20 {
  margin-left: 10px;
}

.ml-30 {
  margin-left: 15px;
}

.ml-40 {
  margin-left: 20px;
}

.ml-50 {
  margin-left: 25px;
}

.sttCateClass {
  width: 6%;
}

.nameCateClass {
  width: 45%;
}

.codeCateClass {
  width: 45%;
}

.icon:hover {
  background-color: #90c6fc;
}

.container-fluid {
  height: 100%;
  display: table;
  width: 100%;
  padding: 0;
}

.row-fluid {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.centering {
  float: none;
  margin: 0 auto;
}

.dropdown-menu li {
  line-height: 15px;
}

.arrow-level-0 {
  position: absolute;
  color: #464e5f;
  right: 30px;
  font-size: 20px;
  top: 5px;
}

.action-level-1 {
  position: absolute;
  top: 5px;
  right: 20px;
}

.action-level-1 i {
  color: #464e5f;
  font-size: 18px;
}

.action-level-1 .arrow-down {
  margin-right: 10px;
}

.action-level-2 {
  position: absolute;
  top: 5px;
  right: 20px;
}

.action-level-2 i {
  color: #464e5f;
  font-size: 18px;
}

.action-level-2 .arrow-down {
  margin-right: 10px;
}

.action-level-3 {
  position: absolute;
  top: 5px;
  right: 20px;
}

.action-level-3 i {
  color: #464e5f;
  font-size: 18px;
}

.action-level-3 .arrow-down {
  margin-right: 10px;
}

.list-level-0 {
  position: relative;
  list-style-type: none;
  font-weight: bolder;
  color: #464e5f;
}

.categories_quantity {
  position: absolute;
  top: 6px;
  right: 50px;
  font-weight: lighter;
}

.list-level-1 {
  list-style-type: decimal;
  margin-left: 10px;
  line-height: 40px;
  position: relative;
}

.list-level-1 .arrow-down-level-1 {
  position: absolute;
  color: #464e5f;
  right: 66px;
  top: 18px;
}

.list-level-2 {
  list-style-type: circle;
  margin-left: 12px;
  line-height: 35px;
  position: relative;
}

.list-level-2 .arrow-down-level-2 {
  position: absolute;
  color: #464e5f;
  right: 66px;
  top: 18px;
}

.list-level-3 {
  list-style-type: square;
  margin-left: 24px;
  line-height: 35px;
  position: relative;
}

.collapsing {
  transition: none !important;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import {
  checkPermission,
  checkPermissions,
} from '../../../utils/saveDataToLocal';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { VclTable } from 'vue-content-loading';
import { required } from 'vuelidate/lib/validators';
import { chunk } from 'lodash';
import {
  formatDate,
  getDeepByDescendant,
  getDescendantCategories,
  removeAccents,
} from '@/utils/common';
import ListItemCate from '@/view/components/ListItemCate.vue';
import ListItemCateV2 from '@/view/components/ListItemCateV2.vue';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { TIME_TRIGGER } from '@/utils/constants';
import debounce from 'debounce';
// import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import featureFlagMixins from '@/view/mixins/feature-flag.mixins';
import { CATEGORY_APPROVAL_FIELDS } from '@/view/pages/categories/constants/table';
import {
  CATEGORY_APPROVAL_MODIFICATION_TYPE_OPTIONS,
  CATEGORY_APPROVAL_MODIFICATION_TYPES,
  CATEGORY_APPROVAL_STATUSES,
} from '@/view/pages/categories/constants/enum';
// import { dummyData } from '@/view/pages/categories/constants/dump';
import { CATEGORY_APPROVAL_MODIFICATION_TYPE_NAMES } from '@/view/pages/categories/constants/enum-name';
import { CATEGORY_APPROVAL_STATUS_NAMES } from '@/view/pages/categories/constants/constants';
import { CLIENT_DATE_TIME_NO_SECONDS } from '@/utils/date';
import Multiselect from 'vue-multiselect';
import { STATUS_CODE } from '@/utils/enum';

export default {
  mixins: [validationMixin, featureFlagMixins],
  data() {
    return {
      searchName: '',
      searchCode: '',
      show: false,
      fields: [
        {
          key: 'stt',
          label: 'STT',
          sortable: false,
          thStyle: { textAlign: 'center', fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'name',
          label: 'Tên danh mục',
          sortable: false,
          tdClass: 'nameCateClass',
          thClass: 'nameCateClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'code',
          label: 'Mã danh mục',
          sortable: false,
          tdClass: 'codeCateClass',
          thClass: 'codeCateClass',
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        { key: 'actions', label: '' },
      ],
      listItem: [],
      page: 1,
      count: 1,
      listCate: [],
      selectedParent: null,
      listMergeTreeCate: [],
      targetCateToBeMerged: null,
      cateToBeMerged: null,
      name: '',
      code: '',
      valid: true,
      cateId: 0,
      totalRow: 0,
      onLoading: false,
      submitted: false,
      isNew: true,
      chunkData: [],
      isLoadMore: false,
      categoryMerges: [],
      categoryMerge: {
        picked: {},
        merged: {},
        mergedId: null,
      },
      listCateParent: [],
      parentCateId: null,
      parentCateName: '',
      parentCateCode: '',
      listPurchasingDepartment: [],
      optionsPurchasingDepartment: [],
      purchasingDepartmentId: null,
      searchPurchasingDepartment: '',
      categoryApprovals: [],
      // categoryApprovals: dummyData,
      requestReason: '',
      listStatus: CATEGORY_APPROVAL_STATUS_NAMES,
      selectedStatus: [],
      selectedEmployees: [],
      employeeList: [],
      selectedSearchMethods: [],
      searchMethodOptions: CATEGORY_APPROVAL_MODIFICATION_TYPE_OPTIONS,
      selectedCategoryId: null,
      currentPage: 1,
      numberOfPage: 1,
      limit: 10,
      totalItems: 1,
      isBusy: false,
    };
  },
  components: {
    Multiselect,
    KTCodePreview,
    VclTable,
    ListItemCate,
    Treeselect,
    Autosuggest,
    ListItemCateV2,
  },
  validations: {
    name: {
      required,
    },
    code: {
      required,
    },
    requestReason: {
      required,
    },
  },
  created() {
    this.getListCate();
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Danh mục sản phẩm' }]);
    this.show = false;

    // this.$nextTick(function () {
    //   window.addEventListener('scroll', this.onScrollCategory);
    //   this.onScrollCategory();
    // });
  },
  // beforeDestroy() {
  //   window.removeEventListener('scroll', this.onScrollCategory);
  // },
  computed: {
    CATEGORY_APPROVAL_MODIFICATION_TYPES() {
      return CATEGORY_APPROVAL_MODIFICATION_TYPES;
    },
    CATEGORY_APPROVAL_STATUSES() {
      return CATEGORY_APPROVAL_STATUSES;
    },
    isEnabledCategoryApproval() {
      return (
        this.featureFlags.includes('create-update-category-approval') || null
      );
    },
  },
  methods: {
    checkPermissions,
    formatDate,
    CLIENT_DATE_TIME_NO_SECONDS() {
      return CLIENT_DATE_TIME_NO_SECONDS;
    },
    CATEGORY_APPROVAL_FIELDS() {
      return CATEGORY_APPROVAL_FIELDS;
    },
    checkPermission,
    onHide(evt) {
      if (evt.trigger === 'backdrop') {
        this.parentCateId = null;
        this.parentCatename = '';
        this.parentCateCode = '';
      }
    },
    debounceShowAllCollapse: debounce(function () {
      this.showAllCollapse();
    }, TIME_TRIGGER),
    searchCate() {
      this.fetchData();
      if (this.searchName.trim() || this.searchCode.trim()) {
        this.debounceShowAllCollapse();
      }
    },
    resetData() {
      this.searchName = '';
      this.searchCode = '';
      this.fetchData();
    },
    showAllCollapse() {
      this.show = true;
      // show collapse lv 1
      for (const item of this.listItem) {
        this.$refs[`collapse-${item.id}`].$data.show = true;
      }
      for (const item of this.$refs.collapseAll) {
        // show collapse lv 2
        if (item.$refs[`collapseAllLv2-${item.item.id}`]) {
          item.$refs[`collapseAllLv2-${item.item.id}`].show = true;
        }
        // show collapse lv 3
        if (
          item.$refs[`collapseAllLv3-${item.item.id}`] &&
          item.$refs[`collapseAllLv3-${item.item.id}`].length
        ) {
          for (const itemlv3 of item.$refs[`collapseAllLv3-${item.item.id}`]) {
            itemlv3.show = true;
          }
        }
        // show collapse lv 4
        if (
          item.$refs[`collapseAllLv4-${item.item.id}`] &&
          item.$refs[`collapseAllLv4-${item.item.id}`].length
        ) {
          for (const itemlv4 of item.$refs[`collapseAllLv4-${item.item.id}`]) {
            itemlv4.show = true;
          }
        }
        // show collapse lv 5
        if (
          item.$refs[`collapseAllLv5-${item.item.id}`] &&
          item.$refs[`collapseAllLv5-${item.item.id}`].length
        ) {
          for (const itemlv5 of item.$refs[`collapseAllLv5-${item.item.id}`]) {
            itemlv5.show = true;
          }
        }
      }
    },
    hideAllCollapse() {
      this.show = false;
      for (const item of this.listItem) {
        this.$refs[`collapse-${item.id}`].$data.show = false;
      }
      for (const item of this.$refs.collapseAll) {
        if (item.$refs[`collapseAllLv2-${item.item.id}`]) {
          item.$refs[`collapseAllLv2-${item.item.id}`].show = false;
        }
        if (
          item.$refs[`collapseAllLv3-${item.item.id}`] &&
          item.$refs[`collapseAllLv3-${item.item.id}`].length
        ) {
          for (const itemlv3 of item.$refs[`collapseAllLv3-${item.item.id}`]) {
            itemlv3.show = false;
          }
        }
        // hide collapse lv 4
        if (
          item.$refs[`collapseAllLv4-${item.item.id}`] &&
          item.$refs[`collapseAllLv4-${item.item.id}`].length
        ) {
          for (const itemlv4 of item.$refs[`collapseAllLv4-${item.item.id}`]) {
            itemlv4.show = false;
          }
        }
        // hide collapse lv 5
        if (
          item.$refs[`collapseAllLv5-${item.item.id}`] &&
          item.$refs[`collapseAllLv5-${item.item.id}`].length
        ) {
          for (const itemlv5 of item.$refs[`collapseAllLv5-${item.item.id}`]) {
            itemlv5.show = false;
          }
        }
      }
    },
    highlightMatchesName(text = '') {
      const searchTerm = this.searchName ? this.searchName.trim() : '';

      const matchExists = removeAccents(text)
        .toLowerCase()
        .includes(removeAccents(searchTerm).toLowerCase());

      if (!matchExists) return text;

      const regex = new RegExp(searchTerm, 'ig');
      return text.replace(
        regex,
        (matchedText) =>
          `<strong style="color: #FF2400">${matchedText}</strong>`,
      );
    },
    highlightMatchesCode(text = '') {
      const searchTerm = this.searchCode ? this.searchCode.trim() : '';

      const matchExists = removeAccents(text)
        .toLowerCase()
        .includes(removeAccents(searchTerm).toLowerCase());

      if (!matchExists) return text;

      const regex = new RegExp(searchTerm, 'ig');
      return text.replace(
        regex,
        (matchedText) =>
          `<strong style="color: #FF2400">${matchedText}</strong>`,
      );
    },
    onScrollCategory() {
      if (this.isLoadMore || !this.chunkData.length) return;

      const usersHeading = this.$refs['loadMoreCategory'];
      if (usersHeading) {
        const marginTopUsers = usersHeading.getBoundingClientRect().top;
        const innerHeight = window.innerHeight;
        if (marginTopUsers - innerHeight < -500) {
          this.getDataLoadMore();
        }
      }
    },
    getDataLoadMore() {
      this.isLoadMore = true;
      const nextChunk = this.chunkData.splice(0, 1);
      nextChunk[0].forEach((element) => {
        let item = {
          id: element.id,
          stt: this.count,
          name: element.name,
          code: element.code,
          level: element.level,
          parentId: element.parentId,
        };
        this.listItem.push(item);
        this.count++;
        this.isLoadMore = false;
      });
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    isValidData(data) {
      var format = /[`!@#$%^&*()+\-={};':"|,.<>?~]/;
      return !format.test(data);
    },
    formatCellByLevel(category, field) {
      let tagHtml = '';
      switch (category.level) {
        case 0:
          tagHtml = `<p >${category[field]}</p>`;
          break;
        case 1:
          tagHtml = `<p class="ml-10">${category[field]}</p>`;
          break;
        case 2:
          tagHtml = `<p class="ml-20">${category[field]}</p>`;
          break;
        case 3:
          tagHtml = `<p class="ml-30">${category[field]}</p>`;
          break;
        case 4:
          tagHtml = `<p class="ml-40">${category[field]}</p>`;
          break;
        case 5:
          tagHtml = `<p class="ml-50">${category[field]}</p>`;
          break;
        default:
          break;
      }
      return tagHtml;
    },
    getListCate: function () {
      this.listCate = [];
      const param = {
        name: '',
        code: '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.totalRow = data.data.total_row;
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '-' + element.name;
          } else if (element.level === 2) {
            name = '--' + element.name;
          } else if (element.level === 3) {
            name = '---' + element.name;
          } else if (element.level === 4) {
            name = '----' + element.name;
          } else if (element.level === 5) {
            name = '-----' + element.name;
          } else if (element.level === 6) {
            name = '------' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            name: name,
            level: element.level,
            parentId: element.parentId,
          };
          this.listCate.push(item);
        });
        this.$nprogress.done();
        this.$nprogress.remove();
      });
    },
    getListCateParent(parentId = null) {
      this.listCateParent = [];
      const param = {
        parentId: parentId,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('category/get-list-parent', paramQuery).then(
        ({ data }) => {
          if (data.status === 1) {
            this.listCateParent = data.data.list_cate_parent;
          }
        },
      );
    },
    onFilter() {
      this.fetchData();
    },
    fetchData: async function () {
      this.$nprogress.remove();
      if (!this.searchCode || !this.searchName) {
        this.show = false;
      }
      const param = {
        name: this.searchName,
        code: this.searchCode,
      };
      const paramQuery = {
        params: param,
      };
      this.onLoading = true;
      ApiService.query('category/get-list-category', paramQuery)
        .then(async ({ data }) => {
          this.count = 1;
          this.totalRow = data.data.total_row;
          this.listItem = [];
          const categories = data.data.list_caterogy;
          this.categoryMerges = [...categories];
          // const firstChunk = categories.splice(0, 50);
          categories.forEach((element) => {
            const item = {
              id: element.id,
              stt: this.count,
              name: element.name,
              label: element.name,
              code: element.code,
              level: element.level,
              parentId: element.parentId,
              children: element.children,
            };
            this.listItem.push(item);
            this.count++;
          });
          if (this.searchName || this.searchCode) {
            this.show = false;
          }

          this.chunkData = categories.length ? chunk(categories, 100) : [];

          this.onLoading = false;
        })
        .catch((err) => {
          this.onLoading = false;
          throw new Error(err);
        });
    },
    checkViewOnly: function () {
      let count = 0;
      if (checkPermission('CATE_VIEW')) {
        count++;
      }
      if (checkPermission('CATE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    checkViewOnlyCategoryApproval: function () {
      let count = 0;
      if (checkPermission('CATE_APPROVAL_VIEW_ONLY')) {
        count++;
      }
      if (checkPermission('CATE_APPROVAL_INSERT_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    deleteItem: async function (item) {
      let id = item.id;
      ApiService.post(`category/delete/${id}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.show = false;
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            this.makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            this.makeToastFaile(message);
          }
        });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa danh mục!',
        text: 'Bạn có chắc muốn xóa danh mục này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    addDeleteCategoryApproval() {
      if (!this.requestReason) {
        this.makeToastFaile('Bạn chưa nhập lý do');
        return;
      }
      let data = {
        id: this.cateId,
        modificationType: CATEGORY_APPROVAL_MODIFICATION_TYPES.DELETE,
        requestReason: this.requestReason.trim() || '',
      };
      ApiService.setHeader();
      ApiService.post('category/approval/create-delete', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.getListCate();
            this.show = false;
            this.hideModalDeleteCateApproval();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.data.message);
        });
    },
    showCreateDeleteCategoryApprovalAlert: function (item) {
      this.cateId = item.id;
      this.requestReason = '';
      this.$refs['add-delete-cate-approval-modal'].show();
    },
    editItem: function (item) {
      this.cateId = item.id;
      this.getPuchasingDepartment();
      this.getCategoryById();
      this.showModalUpdateCate();
    },
    getCategoryById: function () {
      ApiService.get(`category/${this.cateId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.name = data.data.name;
            this.code = data.data.code;
            this.selectedParent = data.data.parentId;
            this.purchasingDepartmentId = data.data.purchasingDepartmentId;
            this.searchPurchasingDepartment =
              data.data.purchasingDepartmentName;
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    addChildCate: function (item) {
      this.showModalAddCate();
      this.selectedParent = item.id;
      this.parentCateId = item.id;
      this.parentCateName = item.name;
      this.parentCateCode = item.code;
    },
    showModalAddCate() {
      this.isNew = true;
      this.selectedParent = null;
      this.name = '';
      this.code = '';
      this.$refs['add-cate-modal'].show();
    },
    showModalAddCateApproval() {
      this.isNew = true;
      this.selectedParent = null;
      this.name = '';
      this.code = '';
      this.purchasingDepartmentId = null;
      this.getPuchasingDepartment();
      this.$refs['add-cate-approval-modal'].show();
    },
    resetInput() {
      this.parentCateId = null;
      this.parentCatename = '';
      this.parentCateCode = '';
      this.purchasingDepartmentId = null;
      this.searchPurchasingDepartment = '';
      this.requestReason = '';
    },
    hideModalAddCateApproval() {
      this.$refs['add-cate-approval-modal'].hide();
      this.resetInput();
    },
    hideModalAddEditCateApproval() {
      this.$refs['add-edit-cate-approval-modal'].hide();
      this.resetInput();
    },
    showModalCategoryApprovals() {
      this.selectedStatus = CATEGORY_APPROVAL_STATUSES.ALL;
      this.getEmployee();
      this.fetchCategoryApprovals();

      this.$refs['cate-approvals-modal'].show();
    },
    hideModalCategoryApprovals() {
      this.$refs['cate-approvals-modal'].hide();
    },
    hideModalAddCate() {
      this.$refs['add-cate-modal'].hide();
      this.parentCateId = null;
      this.parentCatename = '';
      this.parentCateCode = '';
    },
    showModalUpdateCate() {
      this.isNew = true;
      this.$refs['update-cate-modal'].show();
    },
    hideModalUpdateCate() {
      this.$refs['update-cate-modal'].hide();
    },
    createCategory: function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (!this.isValidData(this.name)) {
        alert('Tên danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      if (!this.isValidData(this.code)) {
        alert('Mã danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      // const name = this.name ? this.name.trim() : '';
      // const code = this.code ? this.code.trim() : '';
      const name = this.name;
      const code = this.code;
      const parentId = this.parentCateId
        ? this.parentCateId
        : this.selectedParent;
      const data = {
        code: code,
        name: name,
        parentId: parentId,
      };
      ApiService.post('category/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.getListCate();
            this.hideModalAddCate();
            this.show = false;
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.data.message);
        });
    },
    createCategoryApproval: function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (!this.isValidData(this.name)) {
        alert('Tên danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      if (!this.isValidData(this.code)) {
        alert('Mã danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      // const name = this.name ? this.name.trim() : '';
      // const code = this.code ? this.code.trim() : '';
      const name = this.name;
      const code = this.code;
      const parentId = this.parentCateId
        ? this.parentCateId
        : this.selectedParent;
      const data = {
        code: code,
        name: name,
        parentId: parentId,
        modificationType: CATEGORY_APPROVAL_MODIFICATION_TYPES.CREATE,
        requestReason: this.requestReason.trim(),
        purchasingDepartmentId: this.purchasingDepartmentId,
      };
      ApiService.post('category/approval/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.hideModalAddCateApproval();
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.getListCate();
            this.show = false;
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.data.message);
        });
    },
    createEditCategoryApproval: function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (!this.isValidData(this.name)) {
        alert('Tên danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      const name = this.name ? this.name.trim() : '';
      const code = this.code ? this.code.trim() : '';
      let data = {
        id: this.cateId,
        name: name,
        code: code,
        parentId: this.selectedParent,
        modificationType: CATEGORY_APPROVAL_MODIFICATION_TYPES.UPDATE,
        requestReason: this.requestReason.trim(),
        purchasingDepartmentId: this.purchasingDepartmentId,
      };
      ApiService.setHeader();
      ApiService.post('category/approval/create', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.getListCate();
            this.hideModalAddEditCateApproval();
            this.show = false;
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.data.message);
        });
    },
    createMergeCategoryApproval: function () {
      if (!this.categoryMerge.merged) {
        this.makeToastFaile('Chưa chọn danh mục để gôp');
        return;
      }
      const data = {
        ...this.categoryMerge,
        modificationType: CATEGORY_APPROVAL_MODIFICATION_TYPES.MERGE,
        requestReason: this.requestReason || '',
      };
      ApiService.post('category/approval/create-merge', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.getListCate();
            this.fetchCategoryApprovals();
            this.hideModalMergeCateApproval();
            this.show = false;
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          const message =
            response.data.message ?? response.$error ?? 'Network error';
          this.makeToastFaile(message);
        });
    },
    hideModalMergeCateApproval: function () {
      this.$refs['add-merge-cate-approval-modal'].hide();
      this.requestReason = '';
    },
    hideModalDeleteCateApproval: function () {
      this.$refs['add-delete-cate-approval-modal'].hide();
      this.requestReason = '';
      this.cateId = null;
    },
    createMoveCategoryApproval: function () {
      const data = {
        sourceCateId: this.cateToBeMerged.id,
        destCateId: this.targetCateToBeMerged,
        modificationType: CATEGORY_APPROVAL_MODIFICATION_TYPES.MOVE,
        requestReason: this.requestReason || '',
      };
      ApiService.post('/category/approval/create-move', data)
        .then((response) => {
          const { data } = response;
          const { status, message } = data;

          if (status === 1) {
            this.makeToastSuccess(message);
            this.fetchData();
            this.getListCate();
            this.hideMoveCategoryApprovalModal();
          } else {
            this.makeToastFaile(message);
            this.hideMoveCategoryApprovalModal();
          }
        })
        .catch(({ response }) => {
          const message =
            response.data.message ?? response.$error ?? 'Network error';
          this.makeToastFaile(message);
        });
    },
    hideMoveCategoryApprovalModal() {
      this.$refs['add-move-cate-approval-modal'].hide();
      this.requestReason = '';
    },
    addEditCategoryApproval(item) {
      this.cateId = item.id;
      this.getPuchasingDepartment();
      this.getCategoryById();
      this.$refs['add-edit-cate-approval-modal'].show();
    },
    addMoveCategoryApproval(item) {
      this.listMergeTreeCate = [];
      const descendants = getDescendantCategories(item, this.listCate);
      const descendantIds = descendants.map((item) => item.id);
      const deep = getDeepByDescendant(item, descendants);

      this.listCate.forEach((cate) => {
        if (
          cate.id !== item.id &&
          item.parentId !== cate.id &&
          !descendantIds.includes(cate.id) &&
          deep + cate.level < 4
        ) {
          this.listMergeTreeCate.push(cate);
        }
      });

      this.cateToBeMerged = item;
      this.targetCateToBeMerged = null;
      this.$refs['add-move-cate-approval-modal'].show();
    },
    addMergeCategoryApproval(category) {
      this.getListCateParent(category.parentId);
      setTimeout(() => {
        const parentCategory = this.listCateParent.find(
          (cate) => cate.id === category.parentId,
        );

        if (!parentCategory) {
          this.makeToastFaile('Danh mục cha không tồn tại');
          return;
        }

        this.categoryMerge = {
          picked: category,
          merged: null,
          mergedId: null,
        };

        this.listMergeTreeCate = [];

        this.listCateParent.forEach((cate) => {
          if (cate.parentId === parentCategory.id && cate.id !== category.id) {
            this.listMergeTreeCate.push(cate);
          }
        });
        this.listMergeTreeCate.unshift(parentCategory);
        this.$refs['add-merge-cate-approval-modal'].show();
      }, 550);
    },
    addChildCateForCategoryApproval(category) {
      this.isNew = true;
      this.selectedParent = null;
      this.name = '';
      this.code = '';
      this.getPuchasingDepartment();
      this.$refs['add-cate-approval-modal'].show();
      this.selectedParent = category.id;
      this.parentCateId = category.id;
      this.parentCateName = category.name;
      this.parentCateCode = category.code;
    },
    updateCategory: async function () {
      this.isNew = false;
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (!this.isValidData(this.name)) {
        alert('Tên danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      if (!this.isValidData(this.code)) {
        alert('Mã danh mục không được chứa ký tự đặc biệt!');
        return;
      }
      const name = this.name ? this.name.trim() : '';
      const code = this.code ? this.code.trim() : '';
      let data = {
        id: this.cateId,
        name: name,
        code: code,
        parentId: this.selectedParent,
        purchasingDepartmentId: this.purchasingDepartmentId,
      };
      ApiService.setHeader();
      ApiService.post('category/update', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
            this.getListCate();
            this.hideModalUpdateCate();
            this.show = false;
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          const message =
            response.data.message ?? response.$error ?? 'Network error';
          this.makeToastFaile(message);
        });
    },
    showMergeTreeModal(item) {
      this.listMergeTreeCate = [];
      const descendants = getDescendantCategories(item, this.listCate);
      const descendantIds = descendants.map((item) => item.id);
      const deep = getDeepByDescendant(item, descendants);

      this.listCate.forEach((cate) => {
        if (
          cate.id !== item.id &&
          item.parentId !== cate.id &&
          !descendantIds.includes(cate.id) &&
          deep + cate.level < 4
        ) {
          this.listMergeTreeCate.push(cate);
        }
      });

      this.cateToBeMerged = item;
      this.targetCateToBeMerged = null;
      this.$refs['merge-tree-cate-modal'].show();
    },
    hideMergeTreeModal() {
      this.$refs['merge-tree-cate-modal'].hide();
    },
    mergeTreeCategory() {
      ApiService.post('/category/move', {
        sourceCateId: this.cateToBeMerged.id,
        destCateId: this.targetCateToBeMerged,
      })
        .then((response) => {
          const { data } = response;
          const { status, message } = data;

          if (status === 1) {
            this.makeToastSuccess(message);
            this.fetchData();
            this.getListCate();
            this.hideMergeTreeModal();
          } else {
            this.makeToastFaile(message);
            this.hideMergeTreeModal();
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    getListCateFiltered() {
      return this.listCate.slice(10);
    },
    popupMergeCate(category) {
      this.getListCateParent(category.parentId);
      setTimeout(() => {
        const parentCategory = this.listCateParent.find(
          (cate) => cate.id === category.parentId,
        );

        if (!parentCategory) {
          this.makeToastFaile('Danh mục cha không tồn tại');
          return;
        }

        this.categoryMerge = {
          picked: category,
          merged: null,
          mergedId: null,
        };

        this.listMergeTreeCate = [];

        this.listCateParent.forEach((cate) => {
          if (cate.parentId === parentCategory.id && cate.id !== category.id) {
            this.listMergeTreeCate.push(cate);
          }
        });
        this.listMergeTreeCate.unshift(parentCategory);
        this.$refs['merge-cate-modal'].show();
      }, 550);
    },
    onChangeMergeCate(value) {
      const merged = this.listCateParent.find((cate) => cate.id === value);
      if (!merged) {
        throw new Error('Danh mục không tồn tại');
      }
      this.categoryMerge.merged = merged;
    },
    onMergeCate() {
      if (!this.categoryMerge.merged) {
        this.makeToastFaile('Chưa chọn danh mục để gôp');
        return;
      }
      const data = {
        ...this.categoryMerge,
      };
      ApiService.put('category/merge', data)
        .then((response) => {
          if (response.data.status === 1) {
            this.makeToastSuccess(response.data.message);
            this.fetchData();
            this.getListCate();
            this.$refs['merge-cate-modal'].hide();
          } else {
            this.makeToastFaile(response.data.message);
          }
        })
        .catch((error) => {
          this.makeToastFaile(error);
        });
    },
    async getPuchasingDepartment() {
      try {
        const params = {
          page: 1,
          limit: 100,
        };
        const {
          data: { data },
        } = await ApiService.query('purchasing-department', { params });
        this.optionsPurchasingDepartment = data.result;
        this.listPurchasingDepartment = [...this.optionsPurchasingDepartment];
      } catch (err) {
        const message = err.response.data.message || 'Network error';
        this.makeToastFaile(message);
      }
    },
    onSelectedPurchasingDepartment({ item }) {
      this.purchasingDepartmentId = item.id;
      this.searchPurchasingDepartment = item.name;
    },
    onInputChangePurchasingDepartment(searchText) {
      if (!searchText) {
        searchText = '';
        this.purchasingDepartmentId = null;
      }
      this.searchPurchasingDepartment = searchText;
      const filteredData = this.optionsPurchasingDepartment
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            removeAccents(item.name.toLowerCase()).indexOf(
              removeAccents(searchText.toLowerCase()),
            ) > -1
          );
        })
        .slice(0, this.limit);

      this.listPurchasingDepartment = [...filteredData];
    },
    getModificationTypeName(modificationType) {
      return CATEGORY_APPROVAL_MODIFICATION_TYPE_NAMES[modificationType];
    },
    getModificationTypeBadgeVariant(modificationType) {
      switch (modificationType) {
        case CATEGORY_APPROVAL_MODIFICATION_TYPES.CREATE:
          return 'primary';
        case CATEGORY_APPROVAL_MODIFICATION_TYPES.UPDATE:
          return 'secondary';
        case CATEGORY_APPROVAL_MODIFICATION_TYPES.MERGE:
          return 'info';
        case CATEGORY_APPROVAL_MODIFICATION_TYPES.MOVE:
          return 'warning';
        case CATEGORY_APPROVAL_MODIFICATION_TYPES.DELETE:
          return 'danger';
        default:
          return 'dark';
      }
    },
    handleFilterByStatus(status) {
      this.selectedStatus = status.id;
      this.currentPage = 1;
      this.fetchCategoryApprovals();
    },
    handlePageChange(pageNum) {
      this.currentPage = pageNum ? parseInt(pageNum) : 1;
      this.fetchCategoryApprovals();
    },
    fetchCategoryApprovals() {
      this.isBusy = true;
      const params = {
        status: this.selectedStatus,
        employeeIds: this.selectedEmployees.map(
          (selectedEmployee) => selectedEmployee.employeeId,
        ),
        modificationTypes: this.selectedSearchMethods,
        categoryId: this.selectedCategoryId,
        page: this.currentPage,
        limit: this.limit,
      };
      ApiService.query(`category/approvals`, {
        params: params,
      })
        .then(({ data }) => {
          if (data.status === STATUS_CODE.SUCCESS) {
            this.categoryApprovals = data.data.result;
            this.currentPage = data.data.currentPage;
            this.totalItems = data.data.totalItems;
            this.numberOfPage = data.data.totalPages;

            this.categoryApprovals.forEach((categoryApproval, index) => {
              categoryApproval['stt'] = index + 1;
            });
            if (this.selectedStatus) {
              this.categoryApprovals = this.categoryApprovals.filter(
                (categoryApproval) =>
                  categoryApproval.status === this.selectedStatus,
              );
            }
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          const message =
            response.data.message ?? response.$error ?? 'Network error';
          this.makeToastFaile(message);
        })
        .then(() => {
          this.isBusy = false;
        });
    },
    getCategoryApprovalStatusName(categoryApprovalStatusValue) {
      return CATEGORY_APPROVAL_STATUS_NAMES[categoryApprovalStatusValue].name;
    },
    getCategoryApprovalStatusBadgeVariant(categoryApprovalStatusValue) {
      switch (categoryApprovalStatusValue) {
        case CATEGORY_APPROVAL_STATUSES.NEW:
          return 'primary';
        case CATEGORY_APPROVAL_STATUSES.APPROVED:
          return 'success';
        case CATEGORY_APPROVAL_STATUSES.DELETED:
          return 'danger';
        default:
          return 'dark';
      }
    },
    showConfirmBox(item) {
      Swal.fire({
        title: 'Xác nhận phiếu!',
        text: 'Bạn có chắc chắn muốn xử lý phiếu này? Vui lòng kiểm tra lại các thông tin thay đổi. Sẽ không thể phục hồi sau đi đã xác nhận.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3699FF',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            const payload = {
              id: item.id,
            };
            ApiService.post('category/approval/approve', payload)
              .then(() => {
                this.fetchCategoryApprovals();
                this.fetchData();
              })
              .catch((error) => {
                const message = error.response.data.message || 'Network error';
                this.makeToastFaile(message);
              });
          }
        })
        .catch(({ response }) => {
          const message =
            response.data.message ?? response.$error ?? 'Network error';
          this.makeToastFaile(message);
        });
    },
    showConfirmDeleteBox(item) {
      Swal.fire({
        title: 'Xác nhận xóa phiếu!',
        text: 'Bạn có chắc chắn muốn xóa phiếu này? Vui lòng kiểm tra lại các thông tin thay đổi.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3699FF',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            const payload = {
              id: item.id,
            };
            ApiService.post('category/approval/delete', payload)
              .then(() => {
                this.fetchCategoryApprovals();
                this.fetchData();
              })
              .catch((error) => {
                const message = error.response.data.message || 'Network error';
                this.makeToastFaile(message);
              });
          }
        })
        .catch(({ response }) => {
          const message =
            response.data.message ?? response.$error ?? 'Network error';
          this.makeToastFaile(message);
        });
    },
    getEmployee() {
      ApiService.query('employees/getAll').then(({ data }) => {
        this.employeeList = data.data.map((item) => {
          return {
            employeeId: item.id,
            employeeName: item.fullName,
          };
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.category-approval-list {
  .nameCateClass {
    text-align: center;
  }
}
</style>

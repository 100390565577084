import { CATEGORY_APPROVAL_STATUSES } from '@/view/pages/categories/constants/enum';

export const CATEGORY_APPROVAL_STATUS_NAMES = [
  {
    id: CATEGORY_APPROVAL_STATUSES.ALL,
    name: 'Tất cả trạng thái',
  },
  {
    id: CATEGORY_APPROVAL_STATUSES.NEW,
    name: 'Mới',
  },
  {
    id: CATEGORY_APPROVAL_STATUSES.APPROVED,
    name: 'Đã xác nhận',
  },
  {
    id: CATEGORY_APPROVAL_STATUSES.DELETED,
    name: 'Đã xóa',
  },
];

import {
  CATEGORY_APPROVAL_MODIFICATION_TYPES,
  CATEGORY_APPROVAL_STATUSES,
} from '@/view/pages/categories/constants/enum';

export const CATEGORY_APPROVAL_MODIFICATION_TYPE_NAMES = Object.freeze({
  [CATEGORY_APPROVAL_MODIFICATION_TYPES.CREATE]: 'Tạo',
  [CATEGORY_APPROVAL_MODIFICATION_TYPES.UPDATE]: 'Cập nhật',
  [CATEGORY_APPROVAL_MODIFICATION_TYPES.DELETE]: 'Xóa',
  [CATEGORY_APPROVAL_MODIFICATION_TYPES.MOVE]: 'Di chuyển',
  [CATEGORY_APPROVAL_MODIFICATION_TYPES.MERGE]: 'Gộp',
})

export const CATEGORY_APPROVAL_STATUS_NAMES = Object.freeze({
  [CATEGORY_APPROVAL_STATUSES.NEW]: 'Mới',
  [CATEGORY_APPROVAL_STATUSES.APPROVED]: 'Đã xác nhận',
  [CATEGORY_APPROVAL_STATUSES.DELETED]: 'Đã hủy',
})
